import React from "react";
import { Container, Box, Heading, Paragraph } from "theme-ui";
import { FormattedMessage } from "gatsby-plugin-intl"
import { StickyProvider } from "contexts/app/app.provider";
import Seo from "components/seo";
import Layout from "components/layout";
import Footer from "../sections/footer/footer";
import "../styles/global.css"

export default function IndexPage() {
    return (
        <StickyProvider>            
            <Layout>                
                <Seo title="World Super League - Rules" />
                <Box as="div" style={{ backgroundColor: "#F5F5F5" }}>
                    <Container sx={styles.page}>
                        <Heading as="h3" sx={styles.subtitle}>WORLD SUPER LEAGUE</Heading>
                        <Heading as="h1" sx={styles.title}><FormattedMessage id="rules.title" /></Heading>
                        
                        <Heading as="h2" sx={{...styles.text, ...styles.textTitle}}>Lorem ipsum dolor sit amet</Heading>
                        <Paragraph sx={styles.text}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            <br />
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </Paragraph>
                        <Heading as="h2" sx={{...styles.text, ...styles.textTitle}}>Lorem ipsum dolor sit amet</Heading>
                        <Paragraph sx={styles.text}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            <br />
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </Paragraph>
                        <Heading as="h2" sx={{...styles.text, ...styles.textTitle}}>Lorem ipsum dolor sit amet</Heading>
                        <Paragraph sx={styles.text}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            <br />
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </Paragraph>
                        <Heading as="h2" sx={{...styles.text, ...styles.textTitle}}>Lorem ipsum dolor sit amet</Heading>
                        <Paragraph sx={styles.text}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            <br />
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </Paragraph>
                        <Heading as="h2" sx={{...styles.text, ...styles.textTitle}}>Lorem ipsum dolor sit amet</Heading>
                        <Paragraph sx={styles.text}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            <br />
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </Paragraph>
                    </Container>
                    <Footer />
                </Box>
            </Layout>
        </StickyProvider>
    );
}

const styles = {
    page: {
        my: 50,
        py: 100,
        minHeight: "calc(100vh - 200px)"
    },
    subtitle: {
        fontFamily: 'Barlow Condensed',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "36px",
        textAlign: "center",
        letterSpacing: "0.15px",
        color: "#D4AF37",
        textTransform: "uppercase"
    },
    title: {
        fontFamily: 'Lexend Zetta',
        fontWeight: 800,
        fontSize: 60,
        textAlign: "center",
        letterSpacing: "-10.85px",
        color: "#000000",
        mb: 80
    },
    text: {
        fontFamily: "Lato",
        fontWeight: 500,
        color: "#333",
        fontSize: 18,  
        mb: 40      
    },
    textTitle: { 
        fontWeight: 900,
        mb: 0
    }
}