import React from "react";
import { Box, Text, Image, Link, Container } from "theme-ui";
import twitter from "assets/footerImages/twitter.svg";
import instagram from "assets/footerImages/instagram.svg";
import discord from "assets/footerImages/discord.svg";
import telegram from "assets/footerImages/telegram.svg";
import youtube from "assets/footerImages/youtube.svg";
import medium from "assets/footerImages/contact.svg";
import linkedin from "assets/footerImages/linkedin.svg";
import data from "../../cms/content/footer.json";

const social = { twitter, instagram, discord, telegram, youtube, medium, linkedin };

export default function Footer() {
    return (
        <Box as="section" id="footer">
            <Box as="div" style={{ backgroundColor: "#000"}}>
                <Container sx={styles.poweredBy}>
                    <Box as="div">
                        <Text as="span" sx={styles.poweredText}>Powered by</Text>
                        {/*<Image src={noku} sx={styles.nokuImage}/>*/}
                        <Text>WSL</Text>
                        <Text as="span" sx={styles.version}>v5.1.60</Text>
                    </Box>
                    <Text as="span" sx={styles.copyright}>Copyright 2022 WSL</Text>
                    <Box as="div" sx={styles.iconsContainer}>
                        { data.map((item, index) => ( 
                            <Link target="_blank" href={item.link} key={index}>
                                <Image src={social[item.name]} alt={`${item.name} image`} sx={styles.social} />      
                            </Link>             
                        )) }
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

const styles = {
    iconsContainer: {
        "@media screen and (max-width: 1023px)": {
            display: "flex",
            justifyContent: "center",
            width: "100%",
            my: "30px",
        }
    },
    nokuImage: {
        width: ["37px", "37px", "75px"],
    },
    poweredBy: {
        backgroundColor: "#000",
        pt: ["21px", "21px", "29px"],
        pb: [null, "16px", "28px"],
        px: ["27px", "60px", "98px"],
        display: "flex",
        justifyContent: "space-between",
        "@media screen and (max-width: 1023px)": {
            px: "28px",
            flexDirection: "row",
            flexWrap: "wrap",
        }
    },
    poweredText: {
        mr: ["10px", "20px", "10px"],
        color: "#fff",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: ["8px", "8px", "10px"],
        lineHeight: "32px",
        letterSpacing: "0.5px",
    },
    copyright: {
        color: "#666",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: ["8px", "8px", "12px"],
        lineHeight: "32px",
        letterSpacing: "1px",
    },
    version: {
        ml: ["5px", "5px", "10px"],
        color: "#4548ED",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: ["8px", "8px", "12px"],
        lineHeight: "32px",
        letterSpacing: "1px",
    },
    social: {
        mx: ["12px", "7px", "13px"],
        width: ["20px", "12px", "20px"],
        height: "auto",
        color: "#666"
    },
}